<template>
  <div class="show-messages" v-if="shown">
    <div v-on:click="clickHandler"
         @mouseover="mouseOverHandler"
         @mouseleave="mouseLeaveHandler">
      <div class="message-icon text-center" >
          <MessageIcon :variant="hover ? 'blue' : 'orange'"></MessageIcon>
      </div>
    </div>
  </div>
</template>

<script>
import MessageIcon from "../../../components/icons/MessageIcon";
export default {
  name: "ShowMessageButton",
  components: {MessageIcon},
  props: {
    shown: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      hover: false
    }
  },
  methods: {
    mouseOverHandler() {
      this.hover = true;
    },
    mouseLeaveHandler() {
      this.hover = false;
    },
    clickHandler() {
      this.hover = false;
      this.$emit('click-message-icon')
    }
  }
}
</script>

<style scoped lang="scss">
.show-messages {
  position: relative;
  height: 100%;
  & > div {

    height: 64px;
    width: 64px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #E83B2C;
    position: absolute;
    bottom: 48px;
    right: 60px;
    border-radius: 50%;

    &:hover {
      background: #316579;
      cursor: pointer;
    }

  }
}
</style>