<template>

  <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.0869141 14.7282C0.0869141 6.59404 6.68095 0 14.8151 0V0C22.9493 0 29.5433 6.59404 29.5433 14.7282V17.2718C29.5433 25.406 22.9493 32 14.8151 32V32C6.68095 32 0.0869141 25.406 0.0869141 17.2718V14.7282Z" fill="white"/>
    <path d="M17.3369 9.25H21.8369V13.75" stroke="#E83B2C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.8369 22.75H8.33691V18.25" stroke="#E83B2C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.8369 9.25L16.5869 14.5" stroke="#E83B2C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.33691 22.75L13.5869 17.5" stroke="#E83B2C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M28.5433 14.7282V17.2718H30.5433V14.7282H28.5433ZM1.08691 17.2718V14.7282H-0.913086V17.2718H1.08691ZM14.8151 31C7.23324 31 1.08691 24.8537 1.08691 17.2718H-0.913086C-0.913086 25.9582 6.12867 33 14.8151 33V31ZM28.5433 17.2718C28.5433 24.8537 22.397 31 14.8151 31V33C23.5016 33 30.5433 25.9582 30.5433 17.2718H28.5433ZM14.8151 1C22.397 1 28.5433 7.14633 28.5433 14.7282H30.5433C30.5433 6.04176 23.5016 -1 14.8151 -1V1ZM14.8151 -1C6.12867 -1 -0.913086 6.04176 -0.913086 14.7282H1.08691C1.08691 7.14633 7.23324 1 14.8151 1V-1Z" fill="white"/>
  </svg>

</template>

<script>
export default {
  name: 'MaximizeIcon',
}

</script>
