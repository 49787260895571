<template>

  <div class="d-flex align-items-center justify-content-center"  ref="main">
    <div v-if="usePdfComponent" class="w-100" style="position: relative">
      <div class="d-flex justify-content-center my-3" v-if="numPages > 1">
        <b-pagination
            v-model="page"
            :total-rows="numPages"
            :per-page="1">
          <template #prev-text>
            <span v-b-tooltip.hover title="Previous page">‹</span>
          </template>
          <template #next-text>
            <span v-b-tooltip.hover title="Next page">›</span>
          </template>
        </b-pagination>
      </div>
      <pdf
          :src="url"
          :page="page"
          ref="pdf"
          style="display: block; width: 100%;"
          @page-loaded="pdfPageLoadedHandler"
          @num-pages="pdfNumPagesHandler"
      ></pdf>
      <div class="d-flex justify-content-center mt-3" v-if="numPages > 1">
        <b-pagination
            v-model="page"
            :total-rows="numPages"
            :per-page="1">
          <template #prev-text>
            <span v-b-tooltip.hover title="Previous page">‹</span>
          </template>
          <template #next-text>
            <span v-b-tooltip.hover title="Next page">›</span>
          </template>
        </b-pagination>
      </div>
    </div>

    <b-img
        v-else-if="!mustHavePreview"
        :src="url"
        fluid
    ></b-img>
    <div v-else-if="isBigFile" class="p-3">
      <h3>Preview unavailable for files greater than 5MB. Please download the file to view.</h3>
    </div>
    <div v-else-if="failMimeType" class="p-3">
      <h3>Preview unavailable for the file. Please download the file to view.</h3>
    </div>
    <div v-else class="p-3">
      <h3>Preview has not been generated yet. Please reload page.</h3>
    </div>

  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import pdf from 'vue-pdf'

export default {
  name: "PreviewArea",
  components: {
    pdf
  },
  props: {
    file: {
      type: Object
    },
    url: {
      type: String
    },
    reload: {
      type: Boolean
    }
  },
  data() {
    return {
      page: 1,
      numPages: 1,
      maxNumPage: 1000,
      k: 1394 / 986
    }
  },
  errorCaptured() {
    return false;
  },
  computed: {
    ...mapGetters('preview/file', ['hasPreview', 'mustHavePreview', 'isPdf']),
    ...mapGetters('preview/file', {previewFile: 'file'}),
    isBigFile: function () {
      return this.previewFile.size / (1024 * 1024) > 5 ;
    },
    failMimeType: function () {
      return ['application/encrypted', 'application/octet-stream', 'message/rfc822', 'application/vnd.ms-outlook'].includes(this.previewFile.mimetype);
    },
    usePdfComponent: function () {
      return this.hasPreview || this.isPdf
    }
  },
  watch: {
    reload() {
      if (this.$refs.pdf) {
        const canvas = this.$refs.pdf.$el.getElementsByTagName('canvas')[0];
        const width = canvas.offsetWidth;
        const newHeight = this.k * width;
        canvas.style.height = `${newHeight}px`;
      }
    }
  },
  mounted() {
    if (!this.usePdfComponent) {
      this.$emit('preview-area-loaded')
    }
  },
  methods: {
    ...mapActions('preview/file',['fetchPreviewFile']),
    pdfPageLoadedHandler() {
      this.$refs.main.scrollIntoView(true);
      const canvas = this.$refs.pdf.$el.getElementsByTagName('canvas')[0];
      setTimeout(() => {
        this.k = canvas.offsetHeight / canvas.offsetWidth;
      }, 400);
      this.$emit('preview-area-loaded')
    },
    pdfNumPagesHandler(event) {
      if (event !== undefined) {
        this.numPages = event < this.maxNumPage ? event : this.maxNumPage
      }
    },



  },
}
</script>

<style scoped>

</style>