<template>
  <Workspace>
    <template v-slot:top-bar>
      <TopBar>
        <template v-slot:title>
          Documents
        </template>
      </TopBar>
    </template>

    <template v-slot:subheader>
      <div class="subheader" :class="{minimized: isOpenRightPanel, hidden: isMaximizedRightPanel}">
        <b-row>
          <b-col md="8" class="overflow-hidden">
            <div class="document-title" v-on:click="backHandler">
              <ArrowLeftIcon ></ArrowLeftIcon>
              <span>{{ file ? file.name : 'File not found' }}</span>
            </div>
          </b-col>
          <b-col md="4" v-if="previewUrl">
            <button class="download-document" v-on:click="clickDownloadHandler">
              <DownloadIcon></DownloadIcon>
              <span>Download</span>
            </button>
          </b-col>
        </b-row>
      </div>
    </template>

    <template v-slot:content>
      <div class="content document-preview-content flex-grow-1">
        <b-overlay :show="isLoading" rounded="sm" class="h-100 document-preview-overlay pb-3">

          <div class="d-flex h-100 document-preview pb-3">
            <div class="left-content" :class="{active: isOpenRightPanel}">

              <div class="pb-5">
                <div class="document-preview-container">
                  <b-overlay :show="isPreviewLoading">
                    <PreviewArea v-if="file && previewUrl" :file="file" :url="previewUrl" :reload="pdfReload"
                                 v-on:preview-area-loaded="previewAreaLoadedHandler"></PreviewArea>
                  </b-overlay>
                </div>
              </div>

            </div>

            <div v-if="previewUrl"
                 :class="{'right-content': true, active: isOpenRightPanel, maximized: isMaximizedRightPanel}"
                 class="document-preview-messages">
              <div class="wrap-messages">
                <ShowMessageButton
                    :shown="!isOpenRightPanel"
                    v-on:click-message-icon="clickMessageIconHandler"></ShowMessageButton>
                <Messages v-if="isOpenRightPanel"
                          :maximized="isMaximizedRightPanel"
                          :can-send="canSendMessages()"
                          v-on:click-maximize="clickMaximizeHandler"
                          v-on:click-close="clickCloseHandler"/>
              </div>
            </div>

          </div>
        </b-overlay>
      </div>

    </template>

  </Workspace>
</template>

<script>
import Workspace from "../layouts/Workspace";
import TopBar from "../layouts/workspace/TopBar";
import ShowMessageButton from "./parts/preview/ShowMessageButton";
import PreviewArea from "./parts/preview/PreviewArea";
import ArrowLeftIcon from "../components/icons/ArrowLeftIcon";
import DownloadIcon from "../components/icons/DownloadIcon";
import {mapActions, mapGetters} from "vuex";
// import ArrowRightIcon from "../components/icons/ArrowRightIcon";
import Messages from "./parts/preview/Messages";

export default {
  name: "Preview",
  components: {
    Messages,
    // ArrowRightIcon,
    Workspace,
    TopBar,
    ShowMessageButton,
    PreviewArea,
    ArrowLeftIcon,
    DownloadIcon
  },
  data() {
    return {
      message: null,
      isOpenRightPanel: false,
      isMaximizedRightPanel: false,
      messageIconShown: true,
      loadingFileData: true,
      pdfReload: false
    }
  },
  computed: {
    ...mapGetters('auth', ["redirectAction"]),
    ...mapGetters('preview/file', ['document', 'file', 'claim', 'previewUrl', "isLoading", 'isPreviewLoading']),
    ...mapGetters("auth/user", ["username", "position", 'userPermissions'],),
    ...mapGetters("auth/user/claim", ["selectedClaim"]),
    ...mapGetters(["prevUrl"]),
    ...mapGetters({
      avatar: 'auth/user/avatar'
    })
  },
  methods: {
    ...mapActions('preview/file', ['fetchFileData', 'downloadFile']),
    ...mapActions('pageNotifications', ['clear']),
    ...mapActions('auth/user/claim', ['selectClaim']),
    ...mapActions('auth/user/client', ['selectClientById']),
    canSendMessages() {
      // file from final claim pack
      if (this.document === null)
        return true;

      return this.document.is_sensitive ? this.hasOnePermission(['messaging.sensitive_docs']) :
                                          this.hasOnePermission(['messaging.non_sensitive_docs']);
    },
    hasOnePermission(permissions) {
      if (this.user === null)
        return false;

      for (const permission of permissions) {
        if (this.userPermissions.indexOf(permission) > -1)
          return true;
      }

      return false;
    },
    clickMaximizeHandler() {
      this.isMaximizedRightPanel = !this.isMaximizedRightPanel
    },
    clickCloseHandler() {
      this.isOpenRightPanel = false
      this.isMaximizedRightPanel = false
    },
    clickMessageIconHandler() {
      this.openRightPanel();
    },
    openRightPanel() {
      this.isOpenRightPanel = true;
      this.messageIconShown = false
    },
    closeRightPanel() {
      this.isOpenRightPanel = false;
    },
    backHandler() {
      if (this.$route.params.type === 'document') {
        this.$router.push({name: 'documents'});
      }
      if (this.$route.params.type === 'claim-pack') {
        this.$router.push({name: 'final-claim-pack'});
      }
    },
    clickDownloadHandler() {
      this.downloadFile({...this.$route.params, 'name': this.file.name})
    },
    previewAreaLoadedHandler() {
      this.loadingFileData = false;
    },
  },
  watch: {
    selectedClaim(newVal, oldVal) {
      if (this.claim !== null && this.selectedClaim && this.claim.id !== this.selectedClaim.id) {
        this.backHandler();
        return;
      }
      if (oldVal && newVal && oldVal.id !== newVal.id) {
        this.backHandler();
      }
    },
    isOpenRightPanel() {
      setTimeout(() => {
        this.pdfReload = !this.pdfReload;
      }, 700);
    }
  },
  mounted() {
    this.loadingFileData = true;
    if (this.redirectAction === 'messages') {
      this.openRightPanel();
    }

    this.fetchFileData(this.$route.params).then(() => {
      if ( this.claim) {
        this.selectClaim(this.claim);
        this.selectClientById(this.claim.organization_id);
      }
    })

    if (this.$route.query.action && this.$route.query.action === 'messages') {
      this.openRightPanel()
    }
  },
  destroyed() {
    this.clear();
  }
}
</script>

<style lang="scss" scoped>
::v-deep .right-side {
  background: #E5E5E5;
}
</style>
