<template>

  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.925781 15.5189C0.925781 6.94806 7.87384 0 16.4447 0C25.0156 0 31.9636 6.94806 31.9636 15.5189V16.4811C31.9636 25.0519 25.0156 32 16.4447 32C7.87384 32 0.925781 25.0519 0.925781 16.4811V15.5189Z" fill="white"/>
    <path d="M13.9258 9.25V11.5C13.9258 11.8978 13.7677 12.2794 13.4864 12.5607C13.2051 12.842 12.8236 13 12.4258 13H10.1758M23.6758 13H21.4258C21.028 13 20.6464 12.842 20.3651 12.5607C20.0838 12.2794 19.9258 11.8978 19.9258 11.5V9.25M19.9258 22.75V20.5C19.9258 20.1022 20.0838 19.7206 20.3651 19.4393C20.6464 19.158 21.028 19 21.4258 19H23.6758M10.1758 19H12.4258C12.8236 19 13.2051 19.158 13.4864 19.4393C13.7677 19.7206 13.9258 20.1022 13.9258 20.5V22.75" stroke="#E83B2C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M30.9636 15.5189V16.4811H32.9636V15.5189H30.9636ZM1.92578 16.4811V15.5189H-0.0742188V16.4811H1.92578ZM16.4447 31C8.42613 31 1.92578 24.4997 1.92578 16.4811H-0.0742188C-0.0742188 25.6042 7.32156 33 16.4447 33V31ZM30.9636 16.4811C30.9636 24.4997 24.4633 31 16.4447 31V33C25.5679 33 32.9636 25.6042 32.9636 16.4811H30.9636ZM16.4447 1C24.4633 1 30.9636 7.50034 30.9636 15.5189H32.9636C32.9636 6.39577 25.5679 -1 16.4447 -1V1ZM16.4447 -1C7.32156 -1 -0.0742188 6.39577 -0.0742188 15.5189H1.92578C1.92578 7.50034 8.42613 1 16.4447 1V-1Z" fill="white"/>
  </svg>


</template>

<script>
  export default {
    name: 'MinimizeIcon',
  }

</script>
