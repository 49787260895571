<template>

  <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.543457 14.7282C0.543457 6.59404 7.1375 0 15.2717 0C23.4058 0 29.9999 6.59404 29.9999 14.7282V17.2718C29.9999 25.406 23.4058 32 15.2717 32C7.1375 32 0.543457 25.406 0.543457 17.2718V14.7282Z" fill="white"/>
    <path d="M20.0435 11.5L11.0435 20.5" stroke="#E83B2C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.0435 11.5L20.0435 20.5" stroke="#E83B2C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M28.9999 14.7282V17.2718H30.9999V14.7282H28.9999ZM1.54346 17.2718V14.7282H-0.456543V17.2718H1.54346ZM15.2717 31C7.68978 31 1.54346 24.8537 1.54346 17.2718H-0.456543C-0.456543 25.9582 6.58521 33 15.2717 33V31ZM28.9999 17.2718C28.9999 24.8537 22.8535 31 15.2717 31V33C23.9581 33 30.9999 25.9582 30.9999 17.2718H28.9999ZM15.2717 1C22.8535 1 28.9999 7.14633 28.9999 14.7282H30.9999C30.9999 6.04176 23.9581 -1 15.2717 -1V1ZM15.2717 -1C6.58521 -1 -0.456543 6.04176 -0.456543 14.7282H1.54346C1.54346 7.14633 7.68978 1 15.2717 1V-1Z" fill="white"/>
  </svg>

</template>

<script>
export default {
  name: 'CloseIcon'
}

</script>
