<template>
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 2L2 9L9 16" stroke="#E83B2C" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2 9H18" stroke="#E83B2C" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "ArrowLeftIcon"
}
</script>

<style scoped>

</style>