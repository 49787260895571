<template>
  <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path d="M29.4495 17.9117C29.4492 17.7689 29.4081 17.6291 29.331 17.5089C29.254 17.3886 29.1442 17.2928 29.0146 17.2328L13.1047 9.80822C12.9659 9.74577 12.8114 9.72701 12.6617 9.75445C12.512 9.78188 12.3742 9.85421 12.2666 9.96183C12.159 10.0694 12.0867 10.2073 12.0592 10.357C12.0318 10.5066 12.0506 10.6612 12.113 10.7999L15.1677 17.9276L12.113 25.0552C12.0551 25.1897 12.0378 25.3382 12.0632 25.4823C12.0887 25.6265 12.1557 25.7601 12.2562 25.8666C12.3656 25.9716 12.5042 26.041 12.6538 26.0656C12.8034 26.0902 12.9569 26.0689 13.0941 26.0045L29.004 18.5799C29.134 18.5222 29.2449 18.4287 29.3237 18.3104C29.4026 18.1921 29.4462 18.0538 29.4495 17.9117ZM14.2715 23.8514L16.5041 18.6594L21.5847 18.6594L21.5847 17.1639L16.4617 17.1639L14.2715 11.972L26.9251 17.9117L14.2715 23.8514Z" fill="#E83B2C"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" transform="translate(17.0293 0.941132) rotate(45)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "SendIcon"
}
</script>

<style scoped>

</style>