<template>
  <div class="message-content" ref="commentsComponent">
    <div class="header" ref="commentsHeader">
      <h4>
        Comments
        <span class="float-right">
          <button class="btn btn-link" v-on:click="$emit('click-maximize')" >
            <MinimizeIcon v-if="maximized" />
            <MaximizeIcon v-if="!maximized" />
          </button>
          <button class="btn btn-link" v-on:click="$emit('click-close')">
            <CloseIcon  />
          </button>
        </span>
      </h4>
    </div>

    <div v-if="isClaimPackClosed" class="p-2" ref="contactAlert">
      <b-alert variant="info" show>
        If you want to contact us please contact us using the information found in the contact us section
      </b-alert>
    </div>

    <b-overlay :show="loadingMessages" rounded="sm" class="p-4">

      <div class="messages-wrapper" ref="commentsContainer" id="commentsContainer"
           :style="commentsContainerStyles">

        <div v-if="messages.length" class="messages" ref="messages">
          <div v-for="(file, index) in messages" :key="file.file.id">
            <h4 class="previous-version" v-if="index > 0">
              <span v-b-tooltip.hover :title="performFileWasReplacedMessage(file, index)">File was replaced</span>
            </h4>
            <div v-for="message in file.messages" :key="message.id">

              <h3 class="unread" v-if="showUnreadSubheader(message, index)">
                <span>Unread</span>
              </h3>

              <div class="message-item" ref="message">
                <div class="user-info"  :data-user-id="message.profile_id">
                  <div class="avatar">
                    {{ message.user_id && fetchAvatar(message.user_id) && '' }}
                    <b-avatar v-if="message.source == 1 && message.user_id && getAvatar(message.user_id)"
                              :src="message.user_id && getAvatar(message.user_id)"></b-avatar>
                    <Avatar v-else/>
                  </div>

                  <div class="user-content">
                    <div class="username">{{ message.firstname }} {{ message.lastname }}<span class="text-danger" v-if="message.user_deleted === 1">(deleted)</span></div>
                    <div class="datetime">{{ message.created_at | date_format("ddd DD MMM YYYY [at] HH:mm") }}</div>
                  </div>
                </div>
                <div class="message-content" v-html="message.text"></div>

              </div>

            </div>
          </div>


        </div>

      </div>



      <div class="input-message-wrap" ref="inputMessageWrap" v-if="canSend">

        <div ref="messageInput"
             @input="(e) => messageChangeHandler(e)" class="message-input"
             contenteditable>
        </div>

        <div v-if="search !== null && searchUsers().length > 0" class="share-users" ref="searchUsersMenu">
          <div class="search-user-item" @click="() => selectUserHandler(user, search)"
               v-for="(user, i) in searchUsers()" :key="i">
            {{ user.firstname }} {{ user.lastname }}
          </div>
        </div>

        <div @click="() => sendMessageHandler($refs[`messageInput`])" class="btn-send">
          <SendIcon/>
        </div>
      </div>

    </b-overlay>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SendIcon from "../../../components/icons/SendIcon";
import MaximizeIcon from "../../../components/icons/MaximizeIcon";
import MinimizeIcon from "../../../components/icons/MinimizeIcon";
import CloseIcon from "../../../components/icons/CloseIcon";
import Avatar from "@/components/icons/Avatar";

export default {
  name: "Messages",
  components: {
    SendIcon,
    MaximizeIcon,
    MinimizeIcon,
    CloseIcon,
    Avatar
  },
  props: {
    maximized: {
      required: true,
    },
    canSend: {
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      unreadSubheaderShown: false,
      messageUserProfiles: new Set(),
      commentsContainerStyles: {
        height: '0px',
        'overflow-y': 'scroll',
        'overflow-x': 'hidden',
      },
    };
  },
  mounted() {
    this.fetchMessages({params: this.$route.params}).then(() => {
      this.resizeObserverInit();
    });
    this.fetchUsers({params: this.$route.params});
  },
  computed: {
    ...mapGetters('preview/messages', [
        "messages", "searchUsers", "users", "loadingMessages", "avatar", "search",
    ]),
    ...mapGetters('preview/file', ['file', ]),
    ...mapGetters('auth/user/claim', [
      'isClaimPackClosed',
    ]),
    ...mapGetters('auth/user/claim', [
      'selectedClaim',
    ]),
    ...mapGetters('user/avatars', ["getAvatar", "isLoadingAvatar"]),
    commentsContainerHeight() {
      if (!this.$refs.commentsContainer)
        return 0;

      return this.$refs.commentsContainer.clientHeight;
    },

  },
  updated() {
    if (this.$refs.message && this.$refs.message[this.$refs.message.length - 1] !== undefined) {
      this.$refs.message[this.$refs.message.length - 1].scrollIntoView({behavior: 'smooth'});
    }
  },
  destroyed() {
    this.clear();
  },
  methods: {
    ...mapActions('preview/messages', [
        "createMessage", "fetchMessages", "addThread", "fetchUsers",
        "searchUser", "clearSearchUser", "getUserById", "clear"
    ]),
    ...mapActions('user/avatars', ["fetchAvatar"]),
    showUnreadSubheader(message, index) {
      if (index < message.length - 1) {
        return false
      }
      const prevMessageIndex = this.messages[index].messages.findIndex((item => item.id === message.id)) - 1;
      if (this.messages[index].messages[prevMessageIndex] === undefined) {
        return message.new;
      }
      return message.new && this.messages[index].messages[prevMessageIndex].new === 0;
    },
    performFileWasReplacedMessage(file, index) {
        return this.messages[index-1].file.name + ' was replaced on ' + file.file.created + ' by ' + file.file.full_name + ' with ' + file.file.name;
    },
    resizeObserverInit() {
      const resizeObserver = new ResizeObserver(() => {
        if (!this.$refs.commentsComponent ||
            !this.$refs.commentsHeader)
          return ;

        const inputMessageWrapHeight = (this.$refs.inputMessageWrap) ? this.$refs.inputMessageWrap.clientHeight : 0;

        const contactAlertHeight = (this.$refs.contactAlert !== undefined) ? this.$refs.contactAlert.clientHeight : 0;

        this.commentsContainerStyles.height =
            (this.$refs.commentsComponent.clientHeight -
             this.$refs.commentsHeader.clientHeight - contactAlertHeight -
             inputMessageWrapHeight) * 0.9 + 'px';

        if (this.$refs.message !== undefined && this.$refs.message.length) {
         this.$refs.message[this.$refs.message.length - 1].scrollIntoView({behavior: 'smooth'});
        }

      });

      if (this.$refs.commentsContainer) {
        resizeObserver.observe(document.getElementById(this.$refs.commentsContainer.id));
      }

    },
    sendMessageHandler(messageInput) {
      if (messageInput.innerHTML.length > 0) {

        const message = messageInput.innerHTML
        messageInput.innerHTML = '';

        this.createMessage({
          message: message,
          params: this.$route.params,
          userProfiles: Array.from(this.messageUserProfiles)
        }).then(() => {
          this.messageUserProfiles = new Set();
          this.fetchMessages({params: {...this.$route.params, } } );
        }).catch(() => {
          // if something goes wrong return the message to input
          messageInput.innerHTML = message;
        });
      }
    },
    selectUserHandler(user, search) {

      let ref = this.$refs.messageInput;
      search = search.replace('\n', '');
      let reg = new RegExp(`(?<!>)(@${search})`);

      ref.innerHTML = ref.innerHTML.replace(reg, `<span contenteditable="false" data-user-id="${user.id}" class='username'>@${user.firstname} ${user.lastname}</span>&nbsp;`);
      this.messageUserProfiles.add(user.profile_id);
      this.clearSearchUser();
      const range = document.createRange();
      range.selectNodeContents(ref);
      range.collapse(false);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
      ref.focus();
    },
    messageChangeHandler() {

      const text = this.$refs.messageInput.innerHTML;

      const search = text.match(/.*(?<search>@(\w*\s?\w*.*)(?!span.*<\/span>)$)/);

      if (search && search.groups && search.groups.search) {
        const searchStr = search.groups.search.replace('@', '');
        this.searchUser({search: searchStr});
      } else {
        this.searchUser({search: null});
      }

      const spanUsers = this.$refs.messageInput.getElementsByClassName('username');

      spanUsers.forEach(item => {

        const user = this.users.find(user => {
          return +user.id === +item.dataset.userId
        });

        const content = item.innerText;
        if (user) {
          if (content.length < `@${user.firstname} ${user.lastname}`.length) {
            item.remove();
          }
          else if (content !== `@${user.firstname} ${user.lastname}`) {
            item.removeClass('username');
            item.removeAttribute('data-user-id');
          }
        }

      });

    },

    refreshMessagesHandler() {
      this.clear();
      this.fetchMessages({params: this.$route.params});
      this.fetchUsers({params: this.$route.params});
    }
  }
}
</script>

<style scoped>

</style>