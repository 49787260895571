import { render, staticRenderFns } from "./DownloadIcon.vue?vue&type=template&id=1cdc5050&scoped=true&"
import script from "./DownloadIcon.vue?vue&type=script&lang=js&"
export * from "./DownloadIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cdc5050",
  null
  
)

export default component.exports