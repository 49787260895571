<template>
  <svg :style="{height: height + 'px'}" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0762 10.5058C14.4399 10.1775 14.4767 9.60713 14.1585 9.2319C13.8403 8.85668 13.2875 8.81865 12.9238 9.14698L10.875 10.9966V2.15278C10.875 1.65419 10.4832 1.25 10 1.25C9.51675 1.25 9.125 1.65419 9.125 2.15278V10.9966L7.07619 9.14698C6.71251 8.81865 6.15972 8.85668 5.8415 9.2319C5.52327 9.60713 5.56013 10.1775 5.92381 10.5058L9.42381 13.6655C9.75371 13.9633 10.2463 13.9633 10.5762 13.6655L14.0762 10.5058ZM3 12.9861C3 12.4875 2.60825 12.0833 2.125 12.0833C1.64175 12.0833 1.25 12.4875 1.25 12.9861V15.6944C1.25 16.6916 2.0335 17.5 3 17.5H17C17.9665 17.5 18.75 16.6916 18.75 15.6944V12.9861C18.75 12.4875 18.3582 12.0833 17.875 12.0833C17.3918 12.0833 17 12.4875 17 12.9861V15.6944H3V12.9861Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "DownloadIcon",
  props: {
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped>

</style>